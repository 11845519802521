/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text from '../../components/content/Text'
import MasonryWall, {
	MasonryWallWorkItem,
} from '../../components/content/MasonryWall'
import { Row, Cell } from '../../components/Grid'
import { media, THEME_CITRON } from '../../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../../components/Slide'
import { LayoutIndented } from '../../components/content/Layout'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import { CopyButtonInline } from '../../components/CopyButton'
import { workData } from '../../workData'

const WorkIndexPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		fragment ImageWorkTile on File {
			childImageSharp {
				fluid(maxWidth: 640, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}

		query {
			youtube: file(relativePath: { eq: "work-index/youtube.png" }) {
				...ImageWorkTile
			}
			unqork: file(relativePath: { eq: "work-index/unqork.png" }) {
				...ImageWorkTile
			}
			waze: file(relativePath: { eq: "work-index/waze.jpg" }) {
				...ImageWorkTile
			}
			workrise: file(relativePath: { eq: "work-index/workrise.png" }) {
				...ImageWorkTile
			}
			toyota: file(relativePath: { eq: "work-index/toyota.jpg" }) {
				...ImageWorkTile
			}
			toyotard: file(relativePath: { eq: "work-index/toyotard.png" }) {
				...ImageWorkTile
			}
			ebay: file(relativePath: { eq: "work-index/ebay.png" }) {
				...ImageWorkTile
			}
			equinox: file(relativePath: { eq: "work-index/equinox.jpg" }) {
				...ImageWorkTile
			}
			facebook: file(relativePath: { eq: "work-index/facebook.png" }) {
				...ImageWorkTile
			}
			google: file(relativePath: { eq: "work-index/google.png" }) {
				...ImageWorkTile
			}
			Marcus: file(relativePath: { eq: "work-index/neiman.png" }) {
				...ImageWorkTile
			}
			nest: file(relativePath: { eq: "work-index/nest.png" }) {
				...ImageWorkTile
			}
			paypal: file(relativePath: { eq: "work-index/paypal.png" }) {
				...ImageWorkTile
			}
			sony: file(relativePath: { eq: "work-index/sony.jpg" }) {
				...ImageWorkTile
			}
			toryBurch: file(relativePath: { eq: "work-index/tory.jpg" }) {
				...ImageWorkTile
			}
			cloudkitchens: file(
				relativePath: { eq: "work-index/cloudkitchens.jpg" }
			) {
				...ImageWorkTile
			}
			coinbase: file(relativePath: { eq: "work-index/coinbase.png" }) {
				...ImageWorkTile
			}
			creditKarma: file(relativePath: { eq: "work-index/creditkarma.png" }) {
				...ImageWorkTile
			}
			bridgegood: file(relativePath: { eq: "work-index/bridgegood.png" }) {
				...ImageWorkTile
			}
			sunrun: file(relativePath: { eq: "work-index/sunrun.png" }) {
				...ImageWorkTile
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Work</title>
				<meta content="Work | Free Association" property="og:title" />
				<meta content="Work | Free Association" name="twitter:title" />
				<meta
					name="description"
					content="Since 2008, we've teamed up with ambitious companies to shape a better future through design and technology."
				/>
				<meta
					property="og:description"
					content="Since 2008, we've teamed up with ambitious companies to shape a better future through design and technology."
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1>Proven through partnership.</h1>
							<p>
								Since 2008, we've teamed up with ambitious companies to shape a
								better future through design and technology.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<Row>
						<Cell
							cols={{
								[media.small]: 12,
								[media.medium]: 10,
								[media.large]: 12,
							}}
							offset={{
								[media.small]: 0,
								[media.medium]: 1,
								[media.large]: 0,
							}}
						>
							<MasonryWall>
								{workData.map(item => {
									return (
										<MasonryWallWorkItem
											key={item.id}
											fluidImageData={data[item.id].childImageSharp.fluid}
											title={item.title}
											description={item.description}
											to={item.to}
										/>
									)
								})}
							</MasonryWall>
						</Cell>
					</Row>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--headline">Let's work together.</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(WorkIndexPage, THEME_CITRON)
