type WorkItem = {
	id: string
	title: string
	description: string
	to?: string
}

export const workData: WorkItem[] = [
	{
		id: 'facebook',
		title: 'Facebook',
		description:
			"Teaming up to advance brand expression systems behind the world's most-used digital products.",
		to: '/work/facebook',
	},
	{
		id: 'google',
		title: 'Google',
		description:
			'Product vision, design and prototyping for Google Store, Creative Lab, Access & Energy, Retail and Hardware.',
		to: '/work/google',
	},
	{
		id: 'coinbase',
		title: 'Coinbase',
		description:
			"Creating a North Star product vision and brand system for the world's leading cryptocurrency exchange.",
		to: '/work/coinbase',
	},
	{
		id: 'youtube',
		title: 'YouTube',
		description:
			'Building a self-serve marketplace for content creators and brands to grow their audiences and businesses.',
	},
	{
		id: 'toyota',
		title: 'Toyota',
		description:
			'Concepting intelligent and adaptive platforms with TRI-AD, from autonomous driving to smart cities.',
		to: '/work/toyota',
	},
	{
		id: 'sunrun',
		title: 'Sunrun',
		description:
			'Partnering with the leading solar energy company in the U.S. to drive systematic UI/UX innovation.',
	},
	{
		id: 'ebay',
		title: 'eBay',
		description:
			'Establishing the global brand standards platform and design tools uniting 15,000+ makers.',
		to: '/work/ebay',
	},
	{
		id: 'unqork',
		title: 'Unqork',
		description:
			'UI and UX for an Alphabet-backed venture that helps companies build software without code.',
		to: '/work/unqork',
	},
	{
		id: 'paypal',
		title: 'PayPal',
		description:
			"Reshaping UI for the world's leading payment system and advancing its digital product guidelines.",
	},

	{
		id: 'creditKarma',
		title: 'Credit Karma',
		description:
			'Design tooling and automation to improve data visualization across the #1 credit monitoring platform.',
		to: '/work/credit-karma',
	},
	{
		id: 'bridgegood',
		title: 'BRIDGEGOOD',
		description:
			'Building a scalable foundation to amplify the power of mentorship for our non-profit partner.',
	},
	{
		id: 'toyotard',
		title: 'Toyota Research Institute',
		description:
			'Reimagining the future of mobility through UX orchestration and emotionally intelligent driver-AI.',
		to: '/work/toyota-research-institute',
	},

	{
		id: 'workrise',
		title: 'Workrise',
		description:
			'Forging a north star vision, roadmap and implementation system for energy sector workforce management.',
		to: '/work/workrise',
	},

	{
		id: 'waze',
		title: 'Waze',
		description:
			'Product evolution and brand systems for the app that helps reduce traffic, keep people safe, and improve roads.',
		to: '/work/waze',
	},
	{
		id: 'equinox',
		title: 'Equinox',
		description:
			'Defining the luxury gym experience through associate tools and the member app.',
	},
	{
		id: 'toryBurch',
		title: 'Tory Burch',
		description:
			'Transforming UX and brand standards for the new global e-commerce experience at ToryBurch.com.',
		to: '/work/tory-burch',
	},
	{
		id: 'nest',
		title: 'Nest',
		description:
			'A four year partnership focused on improving e-commerce UX and driving revenue for Nest.com.',
	},

	{
		id: 'cloudkitchens',
		title: 'CloudKitchens',
		description:
			'A universal design system to empower UX designers in a multi-brand environment.',
		to: '/work/cloudkitchens',
	},

	{
		id: 'Marcus',
		title: 'Neiman Marcus',
		description:
			'Designing a customer service platform for store associates managing high-end client relationships.',
	},
]

const linkableItems = workData.filter(item => !!item.to)

export const caseStudyLinkData = linkableItems.reduce<
	Record<string, { next: WorkItem }>
>((result, item, idx) => {
	const nextIDX = idx === linkableItems.length - 1 ? 0 : idx + 1
	return {
		...result,
		[item.id]: {
			next: linkableItems[nextIDX],
		},
	}
}, {})
