/** @jsx jsx */
import { jsx } from '@emotion/core'
import Masonry from 'react-masonry-css'
import Img from 'gatsby-image/withIEPolyfill'

import { GRID_GUTTER_WIDTH } from '../Grid'
import { RouterLink, buildInlineLinkStyle } from './Link'
import { media, typeRamp, colors } from '../../constants'

const propTypes = {}

const defaultProps = {}

const MasonryWall = ({ children }) => {
	return (
		<div
			css={{
				'& .--masonry-wall': {
					display: 'flex',
					width: 'auto',

					[media.medium]: {
						marginLeft: `${GRID_GUTTER_WIDTH * -2}%`,
					},

					[media.large]: {
						marginLeft: `${GRID_GUTTER_WIDTH * -2}%`,
					},
				},

				'& .--masonry-wall-col': {
					[media.medium]: {
						paddingLeft: `${GRID_GUTTER_WIDTH * 2}%`,
					},

					[media.large]: {
						paddingLeft: `${GRID_GUTTER_WIDTH * 2}%`,
					},

					'& > * + *': {
						marginTop: '8rem',

						[media.medium]: {
							marginTop: '8rem',
						},

						[media.large]: {
							marginTop: '16rem',
						},
					},
				},

				'& .--masonry-wall-col:nth-child(2)': {
					[media.medium]: {
						paddingTop: '5rem',
					},

					[media.large]: {
						paddingTop: '22rem',
					},
				},
			}}
		>
			<Masonry
				breakpointCols={{
					default: 2,
					768: 1,
				}}
				className="--masonry-wall"
				columnClassName="--masonry-wall-col"
			>
				{children}
			</Masonry>
		</div>
	)
}

MasonryWall.propTypes = propTypes
MasonryWall.defaultProps = defaultProps

export default MasonryWall

export const MasonryWallWorkItem = ({
	to,
	title,
	description,
	fluidImageData,
}) => {
	const Tag = to ? RouterLink : 'div'
	const anchorProps = to ? { to } : {}

	const imgStyle = to
		? {
				'[data-comp="masonry-work-item"]:hover &': {
					transform: 'scale(1.05)',
				},
		  }
		: null

	const titleStyle = to
		? buildInlineLinkStyle({
				hoverSelector: '[data-comp="masonry-work-item"]:hover &',
		  })
		: null

	return (
		<Tag
			data-comp="masonry-work-item"
			{...anchorProps}
			css={{
				display: 'block',
			}}
		>
			<div
				css={{
					marginBottom: '2.5rem',
					'& img': {
						display: 'block',
					},
				}}
			>
				<div
					css={{
						zIndex: 0,
						overflow: 'hidden',
					}}
				>
					<div
						data-sub="image"
						css={[
							{
								transformOrigin: 'center center',
								transition: 'transform 500ms ease-in-out',
							},
							imgStyle,
						]}
					>
						<Img fluid={fluidImageData} alt={title} />
					</div>
				</div>
			</div>
			<div
				css={{
					maxWidth: '18rem',
				}}
			>
				<h3
					css={{
						...typeRamp.med_20,
						marginBottom: '1rem',
					}}
				>
					<span data-sub="title" css={titleStyle}>
						{title}
					</span>
				</h3>
				<p
					css={{
						...typeRamp.light_16,
						color: colors.grey,
					}}
				>
					{description}
				</p>
			</div>
		</Tag>
	)
}
